<template>
  <div class="container">
    <div class="bg-Color tableBox">
      <el-tabs v-model="activeName" @tab-click="tabsClick">
        <el-tab-pane label="已上架" name="offPutaway"></el-tab-pane>
        <el-tab-pane label="未上架" name="noPutaway"></el-tab-pane>
      </el-tabs>
      <div class="">
        <el-form :model="form">
          <searchTitle @clickSearch="clickSearch">
            <template #selectTop>
              <el-form-item label-width="0">
                <el-input
                  size="mini"
                  v-model="queryParams.query"
                  placeholder="门店名/门店编号"
                ></el-input>
              </el-form-item>
            </template>
          </searchTitle>
          <div class="marT10 x-start">
            <el-button
              v-if="activeName == 'noPutaway'"
              type="primary"
              icon="el-icon-plus"
              size="mini"
              @click="batchLaunch(0)"
              >上架
            </el-button>
            <el-button
              v-if="activeName == 'offPutaway'"
              style="background: #fb5f56; border-color: #fb5f56; color: #fff"
              type="primary"
              icon="el-icon-plus"
              size="mini"
              @click="batchLaunch(1)"
              >下架
            </el-button>
            <el-button
              :disabled="selectTable.length < 1"
              style="background: #fb5f56; border-color: #fb5f56; color: #fff"
              type="primary"
              icon="el-icon-delete"
              size="mini"
              @click="deleteBtn"
              >删除
            </el-button>
            <el-button
              v-if="activeName == 'noPutaway'"
              type="info"
              icon="el-icon-plus"
              size="mini"
              @click="synchronization"
              plain
              >同步
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="bg-Color tableBox marT10">
      <el-table
        :data="shopList"
        border
        height="500px"
        @selection-change="selectionChange"
        width="100%"
      >
        <el-table-column type="selection" align="center" width="55" />
        <el-table-column label="序号" type="index" align="center" width="55" />
        <el-table-column prop="date" label="操作" align="center" width="180">
          <template slot-scope="scope">
            <el-button type="text" @click="editShop(scope.row.shopId)">编辑</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="shopId" label="门店" align="center" width="260">
          <template slot-scope="scope">
            <div class="x-start">
              <el-image
                class="showImg marR10"
                :src="scope.row.shopImageUrl"
                :preview-src-list="[scope.row.shopImageUrl]"
              ></el-image>
              <div class="y-start">
                <span>名称：{{ scope.row.shopName }}</span>
                <span>编号：{{ scope.row.shopNo }}</span>
                <span>电话：{{ scope.row.linkEmployeeTel }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="门店地址" align="center" width="300">
          <template slot-scope="scope">
            <div class="y-start">
              <div class="x-f" style="width: 300px">地址：{{ scope.row.regionName }}</div>
              <div class="" style="width: 250px;text-align: left;">
                详细地址：{{ scope.row.shopAddress }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="营业时间" align="center"></el-table-column>
        <el-table-column prop="address" label="上下架状态" align="center">
          <template v-slot="scope">
            <span v-if="scope.row.status == 0">已上架</span>
            <span v-if="scope.row.status == 1">已下架</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="营业状态" align="center">
          <template v-slot="scope">
            <span>营业中/已打烊</span>
            <!--                        <span v-if="scope.row.status == 1">已打烊</span>-->
          </template>
        </el-table-column>
      </el-table>
      <div class="padd15">
        <div class="x-start"> 已选择{{selectTable.length }}条 </div>
        <pagination
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CButton from "@/views/components/c-button/index.vue";
import searchTitle from "@/views/components/searchTitle/index.vue";
import {
  shopInfoList,
  syncBasicShopInfo,
  ShopRemove,
  ShopBatchLaunch,
} from "@/api/O2OMall/manage/shop";

export default {
  name: "index",
  components: {
    CButton,
    searchTitle,
  },
  data() {
    return {
      shopList: [{}], // 门店列表
      selectTable: [], //列表选择数据
      activeName: "offPutaway",
      form: {
        search: "",
      },
      total: 0,
      queryParams: {
        pageSize: 15,
        pageNum: 1,
        status: 0,
      },
      shopIDArr: [], // 列表选择的门店ID数组
    };
  },
  watch: {
    activeName(nV) {
      this.queryParams.pageNum = 1;
      if (this.activeName == "offPutaway") {
        // 已上架
        this.queryParams.status = 0;
      } else {
        // 未上架
        this.queryParams.status = 1;
      }
      this.getList();
    },
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询门店列表 */
    getList() {
      this.loading = true;
      shopInfoList(this.queryParams).then((response) => {
        this.shopList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 同步按钮
    synchronization() {
      this.loading = true;
      syncBasicShopInfo(this.queryParams).then((response) => {
        // this.shopList = response.rows
        // this.total = response.total
        this.loading = false;
      });
      this.getList();
    },
    tabsClick(tab) {
      console.log("切换页面：", tab.name);
      this.activeName = tab.name;
      // this.getList()
    },
    // 搜索按钮
    clickSearch() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    // 门店上下架
    batchLaunch(type) {
      if (this.shopIDArr.length < 1) {
        return this.$message.info("请先选择门店");
      }
      ShopBatchLaunch({ shopIds: this.shopIDArr, status: type }).then((res) => {
        this.$message.success(`门店已${type === 1 ? "下" : "上"}架`);
        this.getList();
      });
    },
    // 删除
    deleteBtn() {
      if (this.shopIDArr.length < 1) {
        return this.$message.info("请先选择门店");
      }
      ShopRemove(this.shopIDArr).then((res) => {
        console.log("删除门店：", res);
        this.$message.success("删除成功");
        this.getList();
      });
    },
    selectionChange(val) {
      this.selectTable = val;
      let Arr = [];
      val.forEach((item) => {
        console.log("选择的数据：", item.shopId);
        Arr.push(item.shopId);
      });
      this.shopIDArr = Arr;
      console.log("选择的数据：", this.shopIDArr);
    },
    // 编辑门店
    editShop(id) {
      console.log("编辑：", id);
      this.$router.push({
        path: "/O2OMall/manage/shopEdit/index",
        query: {
          shopId: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  background: #e9e9e9;

  .tableBox {
    padding: 10px;

    .showImg {
      width: 70px;
      height: 70px;
      cursor: pointer;
    }
  }
}
</style>
