<template>
  <div class="container">
    <topOperatingButton
      :isSubmitAddBtn="false"
      :isExamineBtn="false"
      :isAuditBillBtn="false"
      @submitForm="submit"
      @getQuit="getQuit"
    />
    <cardTitleCom cardTitle="基本信息">
      <template slot="cardContent">
        <div class="bg-Color tableBox">
          <el-form :model="form" :rules="rules" ref="form" label-width="100px">
            <div>
              <el-form-item label="门店编号">
                <el-input
                  class="inputW"
                  v-model="form.shopNo"
                  placeholder="门店编号"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="门店名称" prop="">
                <el-input
                  class="inputW"
                  v-model="form.shopName"
                  placeholder="门店名称"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="linkEmployeeTel">
                <el-input
                  class="inputW"
                  v-model.number="form.linkEmployeeTel"
                  placeholder="门店电话"
                  maxlength="11"
                ></el-input>
              </el-form-item>
              <el-form-item label="微信二维码" v-if="this.type == 'cake'">
                <accessory-upload
                  :title="''"
                  :fileList="weChatFileList"
                  :limit="1"
                  @getFileItems="weChatUploadSuccess"
                  listType="picture-card"
                  @delFileItems="weChatUploadDelete"
                  :noneBtnImg="form.shopServiceImageUrl != ''"
                ></accessory-upload>
              </el-form-item>
              <el-form-item label="门店地址" prop="">
                <el-cascader
                  class="inputW"
                  :options="provinceList"
                  :props="defaultProp"
                  v-model="form.regionId"
                  maxlength="80"
                  filterable
                  clearable
                >
                </el-cascader>
              </el-form-item>
              <el-form-item label="详细地址" prop="" v-if="this.type === 'cake'">
                <div class="x-start">
                  <el-input
                    class="inputW"
                    v-model="form.regionAddress"
                    placeholder="详细地址"
                  ></el-input>
                  <el-button
                    v-if="this.type != 'dianCan'"
                    class="marL10"
                    type="primary"
                    size="mini"
                    @click="searchAddress"
                    >搜索地址</el-button
                  >
                  <!--                            <c-button test="" :bgColor="'#338cf6'" @click="submit"></c-button>-->
                </div>
              </el-form-item>
              <el-form-item label="详细地址" prop="" v-else>
                <div class="x-start">
                  <el-input
                    class="inputW"
                    v-model="form.regionAddress"
                    placeholder="详细地址"
                  ></el-input>
                  <el-button
                    v-if="this.type != 'dianCan'"
                    class="marL10"
                    type="primary"
                    size="mini"
                    @click="searchAddress"
                    >搜索地址</el-button
                  >
                  <!--                            <c-button test="" :bgColor="'#338cf6'" @click="submit"></c-button>-->
                </div>
              </el-form-item>
              <el-form-item label="配送范围" v-if="this.type != 'dianCan'">
                <div class="x-f marB10">
                  以门店为中心
                  <el-input
                    class="inputW130 marL10 marR10"
                    type="number"
                    v-model="form.deliveryDistance"
                  ></el-input>
                  km以内为门店配送范围
                  <!-- <el-button class="marL10" type="primary" size="mini" @click="">保存</el-button> -->
                  <!--                        <c-button class="marL10" test="" bgColor="#338cf6" @click=""></c-button>-->
                </div>
              </el-form-item>
            </div>
            <el-form-item v-if="this.type != 'dianCan'">
              <Map :location="location" :range="form.deliveryDistance" />
            </el-form-item>
            <div class="marT10">
              <el-form-item label="门店图片">
                <accessory-upload
                  :title="''"
                  :fileList="fileList"
                  :limit="1"
                  @getFileItems="uploadSuccess"
                  listType="picture-card"
                  @delFileItems="shopImageUrlUploadDelete"
                  :noneBtnImg="form.shopImageUrl != ''"
                ></accessory-upload>
              </el-form-item>
              <el-form-item
                label="营业时间"
                prop="businessHours"
                :rules="[
                  {
                    required: true,
                    message: '请选择营业时间',
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-radio v-model="form.businessHours" :label="0">全天</el-radio>
                <el-radio v-model="form.businessHours" :label="1">每天重复</el-radio>
                <el-radio v-model="form.businessHours" :label="2">每周重复</el-radio>
                <div class="" v-if="form.businessHours == 1">
                  <div
                    class="x-f marB10"
                    v-for="(item, index) in form.dayTimeSlotItems"
                    :key="index"
                  >
                    <el-time-picker
                      is-range
                      size="mini"
                      v-model="item.times"
                      range-separator="至"
                      start-placeholder="开始时间"
                      value-format="HH:mm:ss"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围"
                    ></el-time-picker>
                    <el-button
                      class="marL10"
                      type="text"
                      size="mini"
                      @click="deleteDayTimeSlotItems(index)"
                      >删除</el-button
                    >
                  </div>
                  <el-button type="text" size="mini" @click="addDayTimeSlotItems"
                    >添加时间</el-button
                  >
                </div>
                <div v-else-if="form.businessHours == 2">
                  <div v-for="(item, index) in form.weekTimeSlotItems" :key="index">
                    <div class="x-f">
                      <div
                        class="days x-f marL10"
                        v-for="(item, index) in item.weekItems"
                        :key="index"
                      >
                        {{ item }}
                      </div>
                      <el-button
                        type="text"
                        class="marL10"
                        @click="addEditDaysItem(index)"
                        >编辑
                      </el-button>
                      <el-button
                        type="text"
                        style="color: #ff0000"
                        class="marL10"
                        @click="deleteDayItem(index)"
                        >删除
                      </el-button>
                    </div>
                    <div class="y-start marL20">
                      <!--                                    <div class="">-->
                      <div
                        class="x-f marB10 marL20"
                        v-for="(date, inx) in item.timeItems"
                        :key="inx"
                      >
                        <el-time-picker
                          is-range
                          size="mini"
                          v-model="date.times"
                          range-separator="至"
                          start-placeholder="开始时间"
                          value-format="HH:mm:ss"
                          end-placeholder="结束时间"
                          placeholder="选择时间范围"
                        ></el-time-picker>
                        <el-button
                          class="marL10"
                          type="text"
                          style="color: #ff0000"
                          size="mini"
                          @click="deleteDateItem(index, inx)"
                          >删除</el-button
                        >
                      </div>
                      <el-button type="text" size="mini" @click="weeklyAddTimes(index)"
                        >添加时间</el-button
                      >
                      <!--                                    </div>-->
                    </div>
                  </div>
                  <el-button type="text" size="mini" @click="addWeek">添加星期</el-button>
                </div>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </template>
    </cardTitleCom>

    <el-dialog
      title="添加星期"
      :visible.sync="addDaysDialog"
      width="30%"
      :before-close="close"
    >
      <div class="weekList">
        <el-checkbox-group v-model="days">
          <el-checkbox v-for="(item, index) in weekList" :label="item" :key="index">
            {{ item }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="addDayOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import CButton from "@/views/components/c-button/index.vue";
import AccessoryUpload from "@/views/components/accessoryUpload/index.vue";
import Map from "@/views/components/map/index.vue";
import { getAllRegion } from "@/api/goods/region";
import { getMallShop, ShopDetail, ShopUpdate } from "@/api/O2OMall/manage/shop";
import { getDetailAPI, updateAPI } from "@/api/O2OThird/orderMealWxa/shop";
import {
  cakefreeselectGetDetail,
  cakefreeselectUpdate,
} from "@/api/O2OThird/cakeFreeSelectApp/shop";
export default {
  name: "index",
  components: {
    cardTitleCom,
    topOperatingButton,
    CButton,
    AccessoryUpload,
    Map,
  },
  data() {
    return {
      rules: {
        linkEmployeeTel: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          //校验手机号的正则：/^1[3456789]\d{9}$/
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
      },
      type: null,
      location: {
        latitude: 39.908821,
        longitude: 116.397469,
      },
      addDaysDialog: false,
      days: [], // 弹框选择的星期
      times: [],
      timeList: [{}],
      weekList: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"],
      selectWeekList: [
        {
          days: [], // 星期几
          date: [
            {
              startTime: "",
              endTime: "",
            },
          ], // 时间列表
        },
      ],
      shopMsg: {}, // 门店信息
      form: {
        regionId: "",
        deliveryDistance: 0,
        linkEmployeeTel: "", // 电话
        shopImageUrl: "", // 图片地址
        shopServiceImageUrl: "", // 二维码图片地址
        addressDetail: "", // 详细地址
        businessHours: 0, // 营业时间
        shopAddress: null,
        regionAddress: null,
        shopId: "",
        shopName: "",
        shopNo: "", // 门店编号
        dayTimeSlotItems: [
          {
            times: "",
          },
        ],
        weekTimeSlotItems: [],
      },
      fileList: [], // 门店图片回显
      weChatFileList: [], // 微信图片回显
      provinceList: [], // 行政区地址列表
      defaultProp: {
        value: "id",
        label: "label",
        emitPath: false,
      },
    };
  },
  created() {
    this.getRegion();
    this.type = this.$route.query.type;
    this.form.shopId = this.$route.query.shopId;
    this.getShopDetail();
  },
  methods: {
    weChatUploadDelete() {
      this.form.shopServiceImageUrl = "";
    },
    shopImageUrlUploadDelete() {
      this.form.shopImageUrl = "";
    },
    async getShopDetail() {
      let res = {};
      if (this.type == "dianCan") {
        res = await getDetailAPI(this.form.shopId);
      } else if (this.type == "cake") {
        res = await cakefreeselectGetDetail(this.form.shopId);
      } else {
        res = await ShopDetail({ shopId: this.form.shopId });
      }
      console.log("门店详情：", res);
      let data = res.data;
      this.form.linkEmployeeTel = data.linkEmployeeTel; // 电话
      this.form.shopNo = data.shopNo; // 门店编号
      this.form.shopName = data.shopName; // 门店名称
      this.form.shopId = data.shopId; // 门店ID
      this.form.regionId = data.regionId; // 区域ID
      this.form.shopAddress = data.shopAddress; // 门店地址
      this.form.regionAddress = data.regionAddress; // 门店地址
      this.form.businessHours = data.businessHours || 0; // 营业时间
      if (this.type == "dianCan") {
        this.form.dayTimeSlotItems = JSON.parse(data.dayTimeSlotItems) || [];
        this.form.weekTimeSlotItems = JSON.parse(data.weekTimeSlotItems) || [];
      } else {
        this.form.dayTimeSlotItems = data.dayTimeSlotItemsList || []; // 每周重复
        this.form.weekTimeSlotItems = data.weekTimeSlotItemsList || []; // 每天重复
      }
      this.form.latitude = data.latitude; // 纬度
      this.form.longitude = data.longitude; // 经度
      this.form.deliveryDistance = data.deliveryDistance;
      this.location = {
        latitude: data.latitude,
        longitude: data.longitude,
      };
      if (data.shopImageUrl != null && data.shopImageUrl != "") {
        this.fileList.push({
          url: data.shopImageUrl,
        });
        this.form.shopImageUrl = data.shopImageUrl;
      }
      if (data.shopServiceImageUrl != null && data.shopServiceImageUrl != "") {
        this.weChatFileList.push({
          url: data.shopServiceImageUrl,
        });
        this.form.shopServiceImageUrl = data.shopServiceImageUrl;
      }
    },
    //获取全部行政地区事件
    getRegion() {
      getAllRegion().then((response) => {
        this.provinceList = response.data;
      });
    },
    tabsClick(tab, event) {},
    // 图片上传成功
    uploadSuccess(data) {
      console.log("上传图片1：", data.accessUrl);
      this.form.shopImageUrl = data.accessUrl;
    },
    weChatUploadSuccess(data) {
      console.log("上传图片1：", data.accessUrl);
      this.form.shopServiceImageUrl = data.accessUrl;
    },
    // 保存提交
    async submit() {
      this.$refs["form"].validate((v) => {
        if (v) {
          console.log("ssssss");
          if (this.form.businessHours == 0) {
            this.form.weekTimeSlotItems = [];
            this.form.dayTimeSlotItems = [];
          } else if (this.form.businessHours == 1) {
            this.form.weekTimeSlotItems = [];
          } else if (this.form.businessHours == 2) {
            this.form.dayTimeSlotItems = [];
          }
          if (this.type == "dianCan") {
            // this.form.weekTimeSlotItems = JSON.stringify(this.form.weekTimeSlotItems)
            // this.form.dayTimeSlotItems = JSON.stringify(this.form.dayTimeSlotItems)
            this.form.shopAddress =
              this.getChidlrenStr(this.provinceList, this.form.regionId).join("") +
              this.form.regionAddress;
            updateAPI(this.form).then((res) => {
              console.log("门店信息修改：", res);
              this.$message.success("门店信息修改成功");
            });
          } else if (this.type == "cake") {
            this.form.dayTimeSlotItemsList = this.form.dayTimeSlotItems;
            this.form.weekTimeSlotItemsList = this.form.weekTimeSlotItems;
            this.form.shopAddress =
              this.getChidlrenStr(this.provinceList, this.form.regionId).join("") +
              this.form.regionAddress;
            delete this.form.dayTimeSlotItems;
            delete this.form.weekTimeSlotItems;
            cakefreeselectUpdate(this.form).then((res) => {
              console.log("门店信息修改：", res);
              this.$message.success("门店信息修改成功");
            });
          } else {
            this.form.shopAddress =
              this.getChidlrenStr(this.provinceList, this.form.regionId).join("") +
              this.form.regionAddress;
            ShopUpdate(this.form).then((res) => {
              console.log("门店信息修改：", res);
              this.$message.success("门店信息修改成功");
            });
          }
        }
      });
    },
    // 每天重复，增加时间段
    addDayTimeSlotItems() {
      this.form.dayTimeSlotItems.push({
        times: "",
      });
    },
    // 删除每天重复时间段
    deleteDayTimeSlotItems(index) {
      this.form.dayTimeSlotItems.splice(index, 1);
    },
    //  关闭选择星期弹框
    close() {
      this.addDaysDialog = false;
    },
    // 打开每周重复添加星期弹框
    addEditDaysItem(index) {
      this.daysItem = index;
      this.addDaysDialog = true;
    },
    // 删除星期Item
    deleteDayItem(index) {
      this.form.weekTimeSlotItems.splice(index, 1);
    },
    // 每周重复添加星期
    addDayOk() {
      console.log("添加星期0", this.form.weekTimeSlotItems);
      console.log("添加星期1", this.daysItem);
      this.form.weekTimeSlotItems[this.daysItem].weekItems = this.days;
      this.addDaysDialog = false;
      // this.days = []
    },
    // 添加星期
    addWeek() {
      this.form.weekTimeSlotItems.push({
        timeItems: [],
        weekItems: [], // 星期几
      });
      this.addEditDaysItem(index);
    },
    // 每周重读添加时间
    weeklyAddTimes(index) {
      console.log("添加", index);
      this.form.weekTimeSlotItems[index].timeItems.push({
        times: "",
      });
    },
    // 删除时间Item
    deleteDateItem(index, inx) {
      this.form.weekTimeSlotItems[index].timeItems.splice(inx, 1);
    },
    getChidlrenStr(tree, id, path = []) {
      if (!path) {
        path = [];
      }
      for (var i = 0; i < tree.length; i++) {
        const tempPath = [...path];
        tempPath.push(tree[i].label);
        if (tree[i].id === id) {
          return tempPath;
        }
        if (tree[i].children) {
          const reuslt = this.getChidlrenStr(tree[i].children, id, tempPath);
          if (reuslt) {
            return reuslt;
          }
        }
      }
    },
    // 搜索地址
    searchAddress() {
      function getChidlren(tree, id, path = []) {
        if (!path) {
          path = [];
        }
        for (var i = 0; i < tree.length; i++) {
          const tempPath = [...path];
          tempPath.push(tree[i].label);
          if (tree[i].id === id) {
            return tempPath;
          }
          if (tree[i].children) {
            const reuslt = getChidlren(tree[i].children, id, tempPath);
            if (reuslt) {
              return reuslt;
            }
          }
        }
      }
      getMallShop({
        address:
          getChidlren(this.provinceList, this.form.regionId).join("") +
          this.form.shopAddress,
      }).then((res) => {
        this.location = {
          latitude: res.data.lat,
          longitude: res.data.lng,
        };
        this.form.latitude = res.data.lat; // 纬度
        this.form.longitude = res.data.lng; // 经度
      });
    },
    //退出
    getQuit() {
      let path = "";
      if (this.type == "dianCan") {
        path = "/O2OThird/orderMealWxa/shop";
      } else if (this.type == "cake") {
        path = "/O2OThird/cakeFreeSelectApp/shop";
      } else {
        path = "/O2OMall/manage/shop";
      }
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  min-height: calc(100vh - 84px);

  .tableBox {
    padding: 10px;

    .inputW {
      width: 400px;
    }

    .inputW130 {
      width: 130px;
    }

    .weekList {
      display: table;
      padding: 15px 20px;
      border: 1px solid #e0e0e0;
      //background: #cccccc;
    }
  }
}
</style>
