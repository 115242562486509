var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("cardTitleCom", {
        attrs: { cardTitle: "签到规则" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15" },
                  [
                    _c("div", { staticClass: "x-f informBox marB10" }, [
                      _c("i", {
                        staticClass: "el-icon-warning marR10",
                        staticStyle: { color: "#155bd3" },
                      }),
                      _c("span", [
                        _vm._v(
                          '签到规则更新：不勾选"领取限制"，表明该会员每次满足设定的连续签到次数时，都可以领取奖励'
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "x-f-end" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.saveBtn },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form",
                      {
                        ref: "ruleForm",
                        attrs: {
                          model: _vm.form,
                          rules: _vm.rules,
                          "label-width": "100px",
                        },
                      },
                      [
                        _c(
                          "el-tabs",
                          [_c("el-tab-pane", [_vm._v("活动信息")])],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "marT20",
                            attrs: { label: "启动状态" },
                          },
                          [
                            _c("el-switch", {
                              staticClass: "inputW",
                              model: {
                                value: _vm.form.isEnableStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isEnableStatus", $$v)
                                },
                                expression: "form.isEnableStatus",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "活动标题" } },
                          [
                            _c("el-input", {
                              staticClass: "inputW",
                              model: {
                                value: _vm.form.activityTitle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "activityTitle", $$v)
                                },
                                expression: "form.activityTitle",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "活动内容" } },
                          [
                            _c("el-input", {
                              staticClass: "inputW",
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 3, maxRows: 4 },
                                placeholder: "请输入内容",
                              },
                              model: {
                                value: _vm.form.activityContent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "activityContent", $$v)
                                },
                                expression: "form.activityContent",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-tabs",
                          [_c("el-tab-pane", [_vm._v("基本奖励")])],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "marT20",
                            attrs: { required: _vm.form.encourageType == 2 },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "1" },
                                    model: {
                                      value: _vm.form.encourageType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "encourageType", $$v)
                                      },
                                      expression: "form.encourageType",
                                    },
                                  },
                                  [_vm._v("赠送积分")]
                                ),
                                _c("el-input", {
                                  staticClass: "inputW160 marL10",
                                  attrs: {
                                    disabled: _vm.form.encourageType == 2,
                                  },
                                  on: { input: _vm.changeInput },
                                  model: {
                                    value: _vm.form.baseRewardIntegral,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "baseRewardIntegral",
                                        $$v
                                      )
                                    },
                                    expression: "form.baseRewardIntegral",
                                  },
                                }),
                                _c("span", { staticClass: "marL10" }, [
                                  _vm._v("积分"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { required: _vm.form.encourageType == 1 } },
                          [
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "2" },
                                    model: {
                                      value: _vm.form.encourageType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "encourageType", $$v)
                                      },
                                      expression: "form.encourageType",
                                    },
                                  },
                                  [_vm._v("随机赠送积分")]
                                ),
                                _c("el-input", {
                                  staticClass: "inputW160 marL10",
                                  attrs: {
                                    disabled: _vm.form.encourageType == 1,
                                    onkeyup:
                                      "value=value.replace(/[^\\d]/g,'')",
                                  },
                                  model: {
                                    value: _vm.form.randomRewardIntegralStart,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "randomRewardIntegralStart",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.randomRewardIntegralStart",
                                  },
                                }),
                                _c("span", { staticClass: "marL10 marR10" }, [
                                  _vm._v("-"),
                                ]),
                                _c("el-input", {
                                  staticClass: "inputW160",
                                  attrs: {
                                    disabled: _vm.form.encourageType == 1,
                                    onkeyup:
                                      "value=value.replace(/[^\\d]/g,'')",
                                  },
                                  model: {
                                    value: _vm.form.randomRewardIntegralEnd,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "randomRewardIntegralEnd",
                                        $$v
                                      )
                                    },
                                    expression: "form.randomRewardIntegralEnd",
                                  },
                                }),
                                _c("span", { staticClass: "marL10" }, [
                                  _vm._v("积分"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-tabs",
                          [_c("el-tab-pane", [_vm._v("额外奖励")])],
                          1
                        ),
                        _vm._l(_vm.templateItems, function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "marT20",
                                  attrs: { label: "连续签到：" },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "inputW160 marL10",
                                    model: {
                                      value: item.continuationSignCount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          item,
                                          "continuationSignCount",
                                          $$v
                                        )
                                      },
                                      expression: "item.continuationSignCount",
                                    },
                                  }),
                                  _c("span", { staticClass: "marL10" }, [
                                    _vm._v("天"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "x-bc" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "marT20",
                                      attrs: {
                                        required: item.encourageType == 2,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: "1" },
                                          model: {
                                            value: item.encourageType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "encourageType",
                                                $$v
                                              )
                                            },
                                            expression: "item.encourageType",
                                          },
                                        },
                                        [_vm._v("赠送积分")]
                                      ),
                                      _c("el-input", {
                                        staticClass: "inputW160 marL10",
                                        attrs: {
                                          disabled: item.encourageType == 2,
                                          onkeyup:
                                            "value=value.replace(/[^\\d]/g,'')",
                                        },
                                        model: {
                                          value: item.outsideRewardIntegral,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "outsideRewardIntegral",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.outsideRewardIntegral",
                                        },
                                      }),
                                      _c("span", { staticClass: "marL10" }, [
                                        _vm._v("积分"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "y-start" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteItem(index)
                                            },
                                          },
                                        },
                                        [_vm._v("删除当前")]
                                      ),
                                      _c("span", [
                                        _vm._v("#" + _vm._s(index + 1)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: { required: item.encourageType == 1 },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "x-f" },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: "2" },
                                          model: {
                                            value: item.encourageType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "encourageType",
                                                $$v
                                              )
                                            },
                                            expression: "item.encourageType",
                                          },
                                        },
                                        [_vm._v("随机赠送积分")]
                                      ),
                                      _c("el-input", {
                                        staticClass: "inputW160 marL10",
                                        attrs: {
                                          disabled: item.encourageType == 1,
                                          onkeyup:
                                            "value=value.replace(/[^\\d]/g,'')",
                                        },
                                        model: {
                                          value: item.randomRewardIntegralStart,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "randomRewardIntegralStart",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.randomRewardIntegralStart",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "marL10 marR10" },
                                        [_vm._v("-")]
                                      ),
                                      _c("el-input", {
                                        staticClass: "inputW160",
                                        attrs: {
                                          disabled: item.encourageType == 1,
                                          onkeyup:
                                            "value=value.replace(/[^\\d]/g,'')",
                                        },
                                        model: {
                                          value: item.randomRewardIntegralEnd,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "randomRewardIntegralEnd",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.randomRewardIntegralEnd",
                                        },
                                      }),
                                      _c("span", { staticClass: "marL10" }, [
                                        _vm._v("积分"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCoupon(index)
                                        },
                                      },
                                      model: {
                                        value: item.isCoupon,
                                        callback: function ($$v) {
                                          _vm.$set(item, "isCoupon", $$v)
                                        },
                                        expression: "item.isCoupon",
                                      },
                                    },
                                    [_vm._v("赠送优惠券 ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "y-start" },
                                    [
                                      _c("span", { staticClass: "marL15" }, [
                                        _vm._v("已选优惠券："),
                                      ]),
                                      _vm._l(
                                        item.couponCaseItems,
                                        function (it, i) {
                                          return _c(
                                            "div",
                                            {
                                              key: it.couponCaseId,
                                              staticClass: "x-f",
                                            },
                                            [
                                              item.couponCaseItems.length > 0
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      staticClass: "marL10",
                                                      attrs: { closable: "" },
                                                      on: {
                                                        close: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteTag(
                                                            i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          it.couponCaseName
                                                        ) + " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("el-input", {
                                                staticClass: "marL10",
                                                staticStyle: { width: "110px" },
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: "输入赠送数量",
                                                },
                                                model: {
                                                  value: it.couponQuantity,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      it,
                                                      "couponQuantity",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "it.couponQuantity",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "领取限制" } },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      model: {
                                        value: item.isGetLimit,
                                        callback: function ($$v) {
                                          _vm.$set(item, "isGetLimit", $$v)
                                        },
                                        expression: "item.isGetLimit",
                                      },
                                    },
                                    [_vm._v("只能获取一次连续签到奖励")]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: _vm.content,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-question marL10",
                                        staticStyle: { color: "#fd6633" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                    _c("el-divider"),
                    _c(
                      "div",
                      { staticClass: "x-c", staticStyle: { width: "100%" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-plus" },
                            on: { click: _vm.addItem },
                          },
                          [_vm._v("新增一级额外奖励")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("SelectCoupon", {
        attrs: { OpenCoupon: _vm.showCoupon },
        on: {
          "update:OpenCoupon": function ($event) {
            _vm.showCoupon = $event
          },
          "update:open-coupon": function ($event) {
            _vm.showCoupon = $event
          },
          getCoupons: _vm.getCoupons,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }